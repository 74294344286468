import { FunctionComponent, useMemo } from "react";

import { useSelector } from "react-redux";

import { t } from "i18next";

import { HomePageBannerContainer, PromotionsSubTitle, PromotionsTitle } from "./styles";

import { availableOffers } from "state/offers-slice";

const HomePageBanner: FunctionComponent<{
  currentPageText?: string;
  background?: string;
  showSubtitle: boolean;
  currentTab?: string;
}> = ({ currentPageText, background, showSubtitle, currentTab }) => {
  const offers = useSelector(availableOffers);

  const SubtitleContent = () => {
    const subtitleContent = useMemo(() => {
      if (currentTab === "available") {
        return offers.length === 0
          ? t("Please check back later")
          : `${t("We have")} ${offers.length === 1 ? "" : offers.length} ${t("Offers available for you")}`;
      } else {
        return t("Information help to download apps");
      }
    }, []);

    return <PromotionsSubTitle>{subtitleContent}</PromotionsSubTitle>;
  };

  return (
    <HomePageBannerContainer background={background}>
      <PromotionsTitle>{currentPageText}</PromotionsTitle>
      {showSubtitle && currentTab && <SubtitleContent />}
    </HomePageBannerContainer>
  );
};

export default HomePageBanner;
